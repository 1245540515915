<div class="pdf-view"
    [ngClass]="getPdfViewClass()"
    [ngStyle]="{'height' :getHeight()}">
  <div class="page-control w-100 position-relative justify-content-center align-items-center">
    <svg-icon src="assets/images/icons/back2.svg"
        class="pointer"
        [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
        style="width: 20px;height: 25px;margin-right: 10px;"
        (click)="changeDocumentPageBack()">
    </svg-icon>
    <div ngbDropdown
        class="d-inline-block"
        autoClose="true">
      <span>{{"UPLOAD.PAGE"|translate}}</span>
      <button class="btn page-btn mx-1"
          ngbDropdownToggle>{{currentDocumentPage}}</button>
      <span>{{"UPLOAD.OF"|translate}}&nbsp;{{totalDocumentPage}}</span>
      <div ngbDropdownMenu>
        <ng-container *ngFor="let page of documentPages">
          <div style="margin: 5px;">
            <button ngbDropdownItem
                [ngClass]="{'active': page === currentDocumentPage}"
                (click)="changeDocumentPage(page)">{{page}}</button>
          </div>
        </ng-container>
      </div>
    </div>
    <svg-icon src="assets/images/icons/next2.svg"
        class="pointer"
        [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"
        style="width: 20px;height: 25px;margin-left: 10px;"
        (click)="changeDocumentPageNext()">
    </svg-icon>

    <div *ngIf="showDownload && !isAccountingRole" 
      class="d-none d-md-block icon position-absolute pointer p-1" style="right: 10px"
      (click)="downloaded.emit()">
      <svg-icon src="assets/images/icon_download2.svg"
          [svgStyle]="{'width.px': 13, 'height.px': 13}"
          [style.--c]="themeList?.header_table_color"></svg-icon>
      <span [style.color]="themeList?.header_table_color"
          class="underline ml-2">{{ 'MEMOS.DOWNLOAD'|translate }}</span>
    </div>

    <div *ngIf="showDownload && isAccountingRole" 
      class="d-none d-md-block icon position-absolute pointer p-1" style="right: 10px"
      (click)="showDownloadPDF = true">
      <svg-icon src="assets/images/icon_download2.svg"
        [svgStyle]="{'width.px': 13, 'height.px': 13}"
        [style.--c]="themeList?.header_table_color"></svg-icon>
      <span [style.color]="themeList?.header_table_color" class="underline ml-2">{{ 'MEMOS.DOWNLOAD'|translate }}</span>
    </div>


  </div>

  <div *ngIf="!pdf"
      class="pdf-shadow bg-color d-flex w-100 page-view justify-content-center align-items-center">
    <div class="custom-pdf-view">
      <div class="spinner-center ">
        <app-loading-spinner></app-loading-spinner>
      </div>
    </div>
  </div>

  <div class="pdf-shadow bg-color"
      [ngClass]="checkPage ==='approvalEmailPage' ? 'page-view-email' : 'page-view'"
      (click)="clickPreview.emit()">
    <div class="p-md-4 p-2">
      <div class="custom-pdf-view"
          #pdfView
          (resized)="pageRendered()">
        <pdf-viewer [src]="{url: pdfFile, httpHeaders: httpHeaders}"
            [ngStyle]="{'height': height + 'px', 'width': 'auto'}"
            [(page)]="currentDocumentPage"
            [render-text]="true"
            [render-text-mode]="1"
            [original-size]="false"
            [autoresize]="true"
            [stick-to-page]="false"
            [fit-to-page]="true"
            [show-all]="false"
            [show-borders]="false"
            (page-rendered)="pageRendered()"
            (after-load-complete)="onLoadPdfComplete($event)">
        </pdf-viewer>
      </div>
    </div>
  </div>
</div>

<app-custom-download-pdf-modal *ngIf="showDownloadPDF"
    [memoDetail]="pdfFile"
    [themeList]="themeList"
    (closeModal)="showDownloadPDF=false">
</app-custom-download-pdf-modal>
